<template>
    <div  >
     
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: true
        }
    },
    created() {
   
    }
}
</script>

<style lang="scss" scoped>
.base-spinner {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000000;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-medium);
    color: var(--featured);
}
</style>